<template>
	<v-app>
        <v-system-bar v-if="$store.state.auth.userClaims.personalID != $store.state.auth.userClaims.realPersonalID" height="32" app color="error" dark>
            Personalnummer: {{$store.state.auth.userClaims.personalID}}
            <v-spacer></v-spacer>
            <v-btn :loading="isDisabelingView" :disabled="isDisabelingView" text @click="switchView">Ansicht beenden</v-btn>
        </v-system-bar>
        <AppNavigation></AppNavigation>
		<v-main>
            <v-divider v-if="!$store.state.app.isNEWApp"></v-divider>
            <v-container fluid>
                <PauseDialog :active="dialogs.pause"></PauseDialog>
                <HelpDialog :active="dialogs.help"></HelpDialog>
                <AlertDialog></AlertDialog>
                <SwitchViewDialog></SwitchViewDialog>
                <div v-if="$store.state.auth.userClaims.permissions.admin || $store.state.auth.userClaims.permissions.leader" class="app-container">
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn
                                v-if="$store.state.auth.userClaims.permissions.admin === true"
                                @click="$store.state.app.dialogs.switchView = true"
                                color="primary"
                                small
                                class="ma-1">
                                <v-icon left>mdi-autorenew</v-icon>
                                Ansicht wechseln
                            </v-btn>
                            <v-btn
                                @click="openUrl('/manage/permissions')"
                                color="primary"
                                small
                                class="ma-1">
                                <v-icon left>mdi-security</v-icon>
                                Rechteverwaltung
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="isLoading" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <div v-else>
                    <router-view />
                </div>
                <v-btn @click="dialogs.help++" fab color="primary" small :class="'custom-fab-help' + (extendedFAB ? ' fab-extended' : '')">
                    <v-icon small>mdi-help</v-icon>
                </v-btn>
                <v-btn v-if="currentPool" @click="dialogs.pause++" fab color="primary" small :class="'custom-fab-pause' + (extendedFAB ? ' fab-extended' : '')">
                    <v-icon small>mdi-silverware-variant</v-icon>
                </v-btn>
            </v-container>
		</v-main>
	</v-app>
</template>

<script>
import AppNavigation from "@/components/app/AppNavigation.vue"
import SwitchViewDialog from "@/components/dialogs/SwitchViewDialog.vue"
import AlertDialog from "@/components/app/AlertDialog.vue"
import HelpDialog from "@/components/dialogs/HelpDialog.vue"
import PauseDialog from "./components/dialogs/PauseDialog.vue"
import { functions, auth } from "@/firebase"
import { httpsCallable } from "firebase/functions"
import { signOut } from "firebase/auth"

export default {
    data() {
        return {
            isDisabelingView: false,
            dialogs: {
                help: 0,
                pause: 0
            }
        }
    },
    computed: {
        isLoading() {
            return this.$store.state.auth.isAuthenticating || !this.$store.state.auth.authDone
        },
        extendedFAB() {
            if (this.$route.name == "plan") {
                return true
            }
            return false
        },
        currentPool() {
            return this.$store.state.pools.selectedPool
        }
    },
    methods: {
        async switchView() {
            this.isDisabelingView = true
            const switchView = httpsCallable(functions, "switchView")
            try {
                const { data } = await switchView()
                if (data.response === "ok") {
                    await signOut(auth)
                    location.reload()
                } else {
                    this.$store.commit("showAlert", {
                        title: "Ansicht wechseln",
                        message: "Beim Wechseln der Ansicht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                    })
                    console.error(data)
                    this.isDisabelingView = false
                }
            } catch (err) {
                this.$store.commit("showAlert", {
                    title: "Ansicht wechseln",
                    message: "Beim Wechseln der Ansicht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                })
                console.error(err)
                this.isDisabelingView = false
            }
        },
        openUrl(url) {
            this.$router.push(url).catch(() => {})
        }
    },
    mounted() {
        this.$store.dispatch("attachConfig")
    },
    components: {
        AppNavigation,
        SwitchViewDialog,
        AlertDialog,
        HelpDialog,
        PauseDialog
    }
}
</script>
<style>
.app-container {
	width: 100%;
	max-width: 1000px !important;
	margin: 0 auto;
}
#loginnew-dropdown .login-new-avatar span {
	display: none;
}
header.v-app-bar {
	contain: unset;
	transform: none !important;
	overflow: visible;
}
.custom-fab-help {
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 9999;
    transition: bottom .25s
}
.custom-fab-pause{
    position: fixed;
    left: 10px;
    bottom: 60px;
    z-index: 9999;
    transition: bottom .25s
}
.custom-fab-help.fab-extended {
    bottom: 70px;
}

.custom-fab-pause.fab-extended{
    bottom: 120px;
}
</style>