<template>
  <v-dialog
  v-model="showDialog" max-width="800px"
  >
    <v-card>
      <v-card-title>Pausenzeiten</v-card-title>
      <v-col>
        <v-card-text v-if="!isEditing" style="padding: 0px;">
          <div style="overflow-x: auto;">
          <v-simple-table style="line-height: 1.5; width: 100%;" class="mobile-table">
            <thead>
              <tr>
                <th style="width: 40%; min-width: 120px;">Arbeitsbereich</th>
                <th style="width: 40%; min-width: 120px;">Plan</th>
                <th style="width: 20%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pauseEntry, index) in message" :key="index">
                <td style="width: 40%; min-width: 120px; font-size: 0.75rem;">
                  {{ pauseEntry.workarea }}<br />
                  Pause:
                </td>
                <td style="width: 40%; min-width: 120px; font-size: 0.75rem;">
                  {{ pauseEntry.schedule.start }} - {{ pauseEntry.schedule.end }}<br />
                  {{ pauseEntry.break.start }} - {{ pauseEntry.break.end }}
                </td>
                <td style="width: 20%; font-size: 0.75rem; padding: 0px; text-align: center;">
                  <v-icon
                    v-if="pauseEntry.note"
                    @click="openDialog(pauseEntry.note)"
                    class="cursor-pointer"
                  >
                    mdi-information-outline
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

          <v-simple-table class="desktop-table" style="width: 100%;">
            <thead>
              <tr>
                <th style="width: 25%; ">Arbeitsbereich</th>
                <th style="width: 25%; ">Plan</th>
                <th style="width: 25%; ">Pause</th>
                <th style="width: 25%; ">Bemerkung</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pauseEntry, index) in message" :key="index">
                <td style="width: 25%;">{{ pauseEntry.workarea }}</td>
                <td style="width: 25%;">{{ pauseEntry.schedule.start }} - {{ pauseEntry.schedule.end }}</td>
                <td style="width: 25%;">{{ pauseEntry.break.start }} - {{ pauseEntry.break.end }}</td>
                <td style="width: 25%;">{{ pauseEntry.note }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

<v-simple-table v-if="isEditing">
  <thead>
    <tr>
      <th style="width: 25%; min-width: 125px;">Arbeitsbereich</th>
      <th style="width: 25%; min-width: 150px;">Plan</th>
      <th style="width: 25%; min-width: 150px;">Pause</th>
      <th style="width: 25%; min-width: 125px;">Bemerkung</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(pauseEntry, index) in message" :key="index">
      <td style="width: 25%">
        <v-text-field v-model="pauseEntry.workarea"></v-text-field>
      </td>
      <td>
        <v-row>
          <v-col cols="6">
            <v-menu
              v-model="pauseEntry.scheduleStartMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pauseEntry.schedule.start"
                  label="Start"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="pauseEntry.schedule.start"
                @change="pauseEntry.scheduleStartMenu = false"
                max-width
                :allowed-minutes="fiveMinuteInterval"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="pauseEntry.scheduleEndMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pauseEntry.schedule.end"
                  label="Ende"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="pauseEntry.schedule.end"
                @change="pauseEntry.scheduleEndMenu = false"
                max-width
                :allowed-minutes="fiveMinuteInterval"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </td>
      <td style="width: 25%">
        <v-row>
          <v-col cols="6">
            <v-menu
              v-model="pauseEntry.breakStartMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pauseEntry.break.start"
                  label="Start"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="pauseEntry.break.start"
                @change="pauseEntry.breakStartMenu = false"
                max-width
                :allowed-minutes="fiveMinuteInterval"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="pauseEntry.breakEndMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pauseEntry.break.end"
                  label="Ende"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="pauseEntry.break.end"
                @change="pauseEntry.breakEndMenu = false"
                max-width
                :allowed-minutes="fiveMinuteInterval"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </td>
      <td style="width: 25%">
        <v-text-field v-model="pauseEntry.note"></v-text-field>
      </td>
      <td>
        <v-btn icon @click="deleteEntry(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</v-simple-table>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="toggleEdit" v-if="isUserAdmin() == true && !isEditing">
          Bearbeiten
        </v-btn>
        <v-btn @click="showDialog = false" v-if="isEditing == false" text color="primary">OK</v-btn>
        <v-btn @click="addEntry" text color="primary" v-if="isEditing">Neue Zeile</v-btn>
        <v-btn @click="setMessage(message)" v-if="isEditing" text color="primary">Speichern</v-btn>
      </v-card-actions>


      <v-dialog v-model="dialog" max-width="200" max-height="200">
      <v-card>
        <v-card-title>Bemerkung</v-card-title>
        <v-card-text>
          {{ dialogContent }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import { firestore } from "@/firebase"
import { collection, doc, setDoc, getDoc } from "firebase/firestore"

export default {
    data: () => ({
        showDialog: false,
        dialog: false,
        dialogContent: "",
        message: [],
        isEditing: false,
        scheduleStartMenu: false,
        scheduleEndMenu: false,
        breakStartMenu: false,
        breakEndMenu: false
    }),
    props: {
        active: {
            type: Number,
            default: 0
        }
    },
    watch: {
        active() {
            this.showDialog = true
        },
        "$store.state.pools.selectedPool": {
            handler: function (selectedPool) {
                if (selectedPool) {
                    this.getMessage()
                }
            },
            immediate: true
        }
    },
    computed: {
        currentPool() {
            return this.$store.state.pools.selectedPool
        }
    },
    methods: {  
        isUserAdmin() {
            if (this.$store.state.auth.userClaims.permissions.admin) {
                return true
            }
        },

        toggleEdit() {
            if(this.isUserAdmin){
                this.isEditing = !this.isEditing
            }
        },
        async setMessage(message) {
            try{
                this.message = message
                const pauseRef = doc(collection(firestore, "pools"), this.$store.state.pools.selectedPool)
                await setDoc(pauseRef, {message: this.message}, {merge: true})
                this.toggleEdit()
            } catch(err){
                this.$store.commit("showAlert", {
                    title: "Pausenzeiten bearbeiten",
                    message: "Es ist ein Fehler beim Bearbeiten der Pausenzeiten aufgetreten. Bitte versuchen Sie es später erneut."
                })
                console.error(err)
            }
        },
        async getMessage() {
            try{
                const pauseRef = doc(collection(firestore, "pools"), this.$store.state.pools.selectedPool)
                const docSnap = await getDoc(pauseRef)
                if (docSnap.exists()) {
                    if(docSnap.data().message){
                        this.message = docSnap.data().message
                    }
                    else{
                        this.message = []
                    }

                }
            } catch (err){
                console.error(err)
            }
        },
        deleteEntry(index) {
            this.message.splice(index, 1)
        },
        addEntry() {
            this.message.push({
                workarea: "",
                schedule: { start: "" , end: "" },
                break: { start: "", end: "" },
                note: ""
            })
        },
        fiveMinuteInterval(minute) {
            return minute % 5 === 0
        },
        openDialog(note) {
            this.dialogContent = note
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
            this.dialogContent = ""
        }
    }
}
</script>
<style scoped>

.v-menu__content
{
  max-width: 100%;
  transform: scale(0.9);
}


  
  @media (max-width: 550px) {

    .v-menu__content {
    transform: scale(0.7); 
  }
    
  .desktop-table {
    display: none;
  }
  .mobile-table {
    display: table;
  }
}

@media (min-width: 551px) {
  .desktop-table {
    display: table;
  }
  .mobile-table {
    display: none;
  }
}

</style>




